<template>
  <div class="template">
    <!-- 标题 -->
    <!-- 编辑模板 label.email.template.edit.template-->
    <div class="title">{{ $t("label.email.template.edit.template") }}</div>
    <!-- 配置新模板 -->
    <div class="list">
      <!-- 标题 -->
      <!-- 选择一个已有的模版作为您新建模版的基础。 front-objectTemplate-V1-Select-a-template-->
      <div class="secTitle">
        {{ $t("front-objectTemplate-V1-Select-a-template") }}
      </div>
      <!-- 主体 -->
      <div class="body">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <!-- 第一行 -->
          <div class="firColumn">
            <!-- <div style="color: red">*</div> -->
            <!-- 适用对象 front-objectTemplate-V1-UseObject-->
            <div class="apply-obj">
              <div class="templateTitle">
                <span style="color: red">*</span>
                {{ $t("front-objectTemplate-V1-UseObject") }}
              </div>
              <div class="Existing-template">
                <el-form-item prop="objectId">
                  <el-select
                    v-model="ruleForm.objectId"
                    placeholder=""
                    filterable
                  >
                    <el-option
                      v-for="item in objectList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      @click.native="changeTemplate(item.id,item.prefix)"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <!-- 记录类型 有记录类型为空的可能设置非必填-->
            <div class="apply-obj">
              <div class="templateTitle">
                {{ $t("label.appupload.component.type.recordtype") }}
              </div>
              <div class="Existing-template">
                <el-form-item>
                  <el-select
                    v-model="ruleForm.recordType"
                    placeholder=""
                    filterable
                  >
                    <el-option
                      v-for="item in recordTypeData"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
          <!-- 第二行 -->
          <div class="firColumn">
            <!-- <div style="color: #fff">*</div> -->
            <!-- 现有模板 lable.Existing.template-->
            <div class="apply-obj">
              <div class="templateTitle">
                <span style="color: #fff">*</span>
                {{ $t("lable.Existing.template") }}
              </div>
              <div class="Existing-template">
                <el-form-item prop="templateId">
                  <el-select
                    v-model="ruleForm.templateId"
                    placeholder="暂无模板"
                    filterable
                  >
                    <el-option
                      v-for="item in templateList"
                      :key="item.templateid"
                      :label="item.templatename"
                      @click.native="changeType(item.type)"
                      :value="item.templateid"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <!-- 是否启用模板 -->
            <div class="apply-check">
              <el-checkbox v-model="isEnableTemplate">
                {{ $t("label_enable_or_no") }}
              </el-checkbox>
            </div>
          </div>
          <!-- 第三行 -->
          <!-- 模板名称 label.emailobject.template.name-->
          <div class="firColumn secColumn">
            <!-- <div style="color: red">*</div> -->
            <div class="templateTitle">
              <span style="color: red">*</span>
              {{ $t("label.emailobject.template.name") }}
            </div>
            <div class="template-name">
              <el-form-item prop="newName">
                <el-input v-model="ruleForm.newName" placeholder=""></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 按钮 -->
      <div class="btnGroup">
        <!--保存 component.telerecord.button.save-->
        <!-- 取消 label.emailsync.button.cancel-->
        <el-button type="primary" @click="submitForm('ruleForm')">{{
          $t("component.telerecord.button.save")
        }}</el-button>
        <el-button @click="goBack">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
      </div>
    </div>
  </div>
</template>




<script>
import * as objectTemplate from "./api";
export default {
  data() {
    return {
      ruleForm: {
        templateId: "", //模板id
        objectId: "", //对象id
        newName: "", //模板名称
        recordType: "", //记录类型
      },
      recordTypeData: [], //记录类型数据
      isEnableTemplate: true, //
      templateType: "",
      rules: {
        //请输入模板名称 lable.Enter.template.name
        //模版名称最长允许50个字符 lable.Maximum.number
        //请选择适用对象 front-template-V1-Applicable-object
        newName: [
          {
            required: true,
            message: this.$i18n.t("lable.Enter.template.name"),
            trigger: "blur",
          },
          {
            max: 50,
            message: this.$i18n.t("lable.Maximum.number"),
            trigger: "blur",
          },
        ],
        objectId: [
          {
            required: true,
            message: this.$i18n.t("front-template-V1-Applicable-object"),
            trigger: "change",
          },
        ],
      },
      templateList: [], //模板数据
      objectList: [], //对象数据
    };
  },
  methods: {
    //获取现有模板
    async changeTemplate(id,prefix) {
      this.ruleForm.recordType='';
      this.getRecordType(prefix);
      this.ruleForm.templateId = "";
      this.templateType = "";
      let resultData = await objectTemplate.getObjTemplateList({ objId: id });
      this.templateList = resultData.data;
      if (this.templateList.length > 0) {
        this.templateList.forEach((item) => {
          if (item.isstd == "1") {
            this.ruleForm.templateId = item.templateid;
            this.templateType = item.type;
          }
        });
      }
    },
    // 获取对象下的记录类型
    async getRecordType(prefix) {
      let resultData = await objectTemplate.getRecordType({ prefix: prefix });
      this.recordTypeData = resultData?.data?.recordTypeList;
      if(this.recordTypeData.length>0){
        this.ruleForm.recordType=this.recordTypeData[0].id
      }
      
    },
    //获取适用对象
    async getApplyObject() {
      let resultData = await objectTemplate.getCanCreatedObjList({});
      if (resultData.result) {
        this.objectList = resultData.data;
      }
    },
    //获取模板type
    changeType(type) {
      this.templateType = type;
    },
    //表单验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.createNewTemplste();
        } else {
          return false;
        }
      });
    },
    //新建模板
    async createNewTemplste() {
      let resultData = await objectTemplate.cteateTemplate({
        id: this.ruleForm.templateId,
        name: this.ruleForm.newName,
        objId: this.ruleForm.objectId,
        templateType: this.templateType,
        recordtype:this.ruleForm.recordType,//记录类型
        isenable:this.isEnableTemplate.toString(),//是否启用
      });
      if (resultData.result) {
        this.$router.push({
          path: "/systemSettings/setuppage/TemplateDetail",
          query: {
            Tid: resultData.data.templateId,
            Tsubid: resultData.data.subId,
            TobjId: this.ruleForm.objectId,
            Tname: this.ruleForm.newName,
            Type: this.templateType,
          },
        });
      }
    },

    choseObj(index) {
      this.name = this.templateList[index].name;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.getApplyObject();
  },
  created() {},
  components: {},
};
</script>


<style lang="scss" scoped>
* {
  margin: 0;
}
.template {
  padding-top: 18px;
  padding-left: 10px;
}
.title {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 26px;
}
.list {
  margin-top: 20px;
  background: #f5f5f5;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}
.secTitle {
  padding: 10px;
  font-size: 16px;
  color: #333333;
  background-color: #f0f0f0;
}
.body {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 30px 92px;
}
.btnGroup {
  display: flex;
  justify-content: center;
  height: 50px;
  padding: 13px 0px;
}
::v-deep .el-button {
  line-height: 0px;
}
.firColumn {
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  .apply-obj {
    display: flex;
    align-items: center;
  }
  .apply-check {
    display: flex;
    align-items: center;
    margin-left: 90px;
  }
}
.secColumn {
}
.templateTitle {
  width: 145px;
  margin-right: 20px;
  text-align: right;
}
.Existing-template {
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
  ::v-deep .created-type {
    width: 120px;
    margin-right: 6px;
  }
  ::v-deep .el-icon-arrow-up:before {
    content: "\e778";
  }
  ::v-deep .el-select .el-input .el-select__caret {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
}
.template-name {
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
    padding-right: 12px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
    width:193px;
    margin-left: 10px !important;
  }
}
input {
  border: none;
  outline: none;
  min-width: 120px;
  padding: 3px 5px 3px 10px;
}
.inputStyle {
  display: relative;
}
.inputStyle i {
  position: absolute;
  right: 8px;
  top: 6px;
}
::v-deep .singleObj {
  padding: 7px 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.objTitle {
  cursor: pointer;
  font-size: 12px;
}
.divStyle:hover {
  background: #f5f5f5 !important;
  color: #006dcc !important;
}
</style>
